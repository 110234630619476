export const Footer = () => {
  const date = new Date();
  return (
    <footer>
      <p>
        <small>
          Can’t see a cookie bar? Confused? That’s because we don’t use cookies.
          That’s right, you don’t have to! We even use an API that suppresses
          Google’s, so the only thing you will see is the cookie that stores
          your session, i.e. images, videos and state of the website. Website by
          Cornell Box©{date.getFullYear()}.
          <br />
          <br />
          The Artist, (Sam Fazakerley and All Associated Works), rights are
          protected by the UK Copyright, Designs and Patents Act 1988 (as
          amended). Purchase of a copyright artwork does not transfer the
          copyright. The copyright to produce the works in copies, to produce
          derivative work based on the copywrited image, and distribute copies
          is retained by the Artist. Any transfer of this copyright must be in
          writing expressly identifying what rights are being sold and for what
          purpose. Works of art cannot be modified without the permission of the
          artist. Works cannot be distributed, mutilated or modified in any way
          that would prejudice the reputation of the Artist.
        </small>
      </p>
    </footer>
  );
};
