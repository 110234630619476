import { useState } from "react";

const encode = (data) => {
  return Object.keys(data)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
};

export const Form = () => {
    const [thanks, setThanks] = useState(false);
    const [details, setDetails] = useState({
      name: "",
      email: "",
    });

    const handleChange = (e) => {
      setDetails({ ...details, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
      //add unique URL to 'POST' address to fox the SW precaching!
      const postUrl = "/?t=" + Math.floor(Date.now() / 1000);


        fetch(postUrl, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "news", ...details }),
        })
          .then(() => setThanks(true))
          .catch((error) => {
            alert(error);
          });

      e.preventDefault();
    };

    return (
      <>
        {thanks ? (
          <h4>Thanks! You're on the list.</h4>
        ) : (
          <form className="grid" name="news" onSubmit={handleSubmit} netlify>
            <input type="hidden" name="form-name" value="news" />
            <input
              className="grid-input"
              placeholder="Name"
              type="name"
              name="name"
              onChange={handleChange}
              required
            />
            <input
              className="grid-input"
              placeholder="Email"
              type="email"
              name="email"
              onChange={handleChange}
              required
            />
            <div className="grid-element" />
            <button type="submit" className="grid-input">
              Submit
            </button>
          </form>
        )}
      </>
    );
}