import { Instagram, LinkedIn, Pinterest, Twitter } from "./graphics/logos";
import { Form } from "./ui/form";
import { Footer } from "./footer";

export const Contact = () => {
  return (
    <>
      <div className="main">
        <div id="form">
          <div className="line" />
          <h2 style={{ textTransform: "uppercase" }}>News & Updates</h2>
          <p>
            None of the works have been released yet for general sale. If you
            would like to be informed about their release, new works, shows and
            galleries; add your details to the form below. Or follow on
            instagram.
          </p>
          <Form />
          <div className="line" />
          <div id="info">
            <p>
              If you are a gallery, institution or need any other info, please
              email, <a href="mailto:info@cornellbox.studio">this address.</a>
            </p>
            <p>
              Sam Fazakerley is also the founder of Cornell Box Ltd. Cornell Box
              handles all requests for brand collaboration, corporate
              installations and adaptations of the work. For any commercial
              enquiries, please contact{" "}
              <a href="mailto:info@cornellbox.studio">this address</a> or visit{" "}
              <a href="https://www.cornellbox.studio">www.cornellbox.studio.</a>
            </p>
          </div>
          <div id="social" className="grid social">
            <Instagram />
            <Pinterest />
            <Twitter />
            <LinkedIn />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
