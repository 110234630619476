import { Route, Router } from "wouter";
import "./App.css";
import { Home } from "./components/home";
import { Contact } from "./components/contact-page";

function App() {
  return (
    <Router>
      <div className="App">
        <Route path="/" component={Home} />

        <Route path="/contact" component={Contact} />
      </div>
    </Router>
  );
}

export default App;
