import { useRef } from "react";
import { AboveTheFold } from "./landing-page";
import { Instagram, LinkedIn, Pinterest, Twitter } from "./graphics/logos";
import { Form } from "./ui/form";
import { Footer } from "./footer";
import { Link } from "wouter";

export const Home = () => {
  const element = useRef();

  function handleHover() {
    element.current.play();
    element.current.style.opacity = 1;
  }

  function handleBlur() {
    element.current.pause();
    element.current.style.opacity = 0;
  }
  return (
    <>
      <AboveTheFold />
      <div className="main">
        <div id="statement">
          <h3>
            Sam is a generative, digital and installation artist, working with
            electrical, mechanical and coded parts. Sam always develops new
            mediums of display; creating a unique sense of depth and
            perspective. Sam draws on mathematical and scientific theories,
            generating complex abstractions and deterministic patterns.
          </h3>
          <p>
            The series of works Super Symmetry refers to the theory of the same
            name. Symmetrical anti-particles exist, that mirror the properties
            of those that make up our world. The theory is contentious, unproven
            and undergirds much of the current research. We have come to hold a
            similar theory, a binary based order where opposites pair-off and
            require each other to exist. Good and evil, light and dark, chaos
            and order. Each piece in the series explores our relationship with
            this duopoly.
            <br />
            <br />
            Inside the work you can see layers of reality expand away from you.
            Each one is a reflection of the previous, a meticulous fantasy,
            where the rules of the previous are reversed and therefore become
            synchronous with the previous.
          </p>
          <Link href="/contact">Contact Us</Link>
        </div>

        <div id="work" className="grid">
          <div className="grid-element">
            <p>The first explores Human and Machine.</p>
          </div>
          <div
            className="grid-element"
            onMouseEnter={handleHover}
            onMouseLeave={handleBlur}
          >
            <div>
              <img src="assets/images/Frame 1_test.jpg" alt="SS #1" />
              <video
                ref={element}
                loop
                preload="none"
                muted
                playsInline
                style={{ opacity: 0 }}
              >
                <source src={"assets/videos/SS_1ind.mp4"} type="video/mp4" />
                <source src={"assets/videos/SS_1ind.webm"} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="description">
              <h5 style={{ marginBottom: 0 }}>Super Symmetry #1</h5>
              <p style={{ margin: 0 }}>
                <em>
                  <small>Human & Machine</small>
                </em>
              </p>
              <p>Code, digital canvas, screen & frame</p>
              <p>
                <small>(On loan)</small>
              </p>
            </div>
          </div>
        </div>
        <div id="form">
          <div className="line" />
          <h2 style={{ textTransform: "uppercase" }}>News & Updates</h2>
          <p>
            None of the works have been released yet for general sale. If you
            would like to be informed about their release, new works, shows and
            galleries; add your details to the form below. Or follow on
            instagram.
          </p>
          <Form />
          <div className="line" />
          <div id="info">
            <p>
              If you are a gallery, institution or need any other info, please
              email, <a href="mailto:info@cornellbox.studio">this address.</a>
            </p>
            <p>
              Sam Fazakerley is also the founder of Cornell Box Ltd. Cornell Box
              handles all requests for brand collaboration, corporate
              installations and adaptations of the work. For any commercial
              enquiries, please contact{" "}
              <a href="mailto:info@cornellbox.studio">this address</a> or visit{" "}
              <a href="https://www.cornellbox.studio">www.cornellbox.studio.</a>
            </p>
          </div>
          <div id="social" className="grid social">
            <Instagram />
            <Pinterest />
            <Twitter />
            <LinkedIn />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
