import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useInView } from "react-spring";
import useMediaQuery from "./hooks/MatchMedia";


export const AboveTheFold = ({ files }) => {
  const [containerRef, isInView] = useInView({});
  const isMobile = useMediaQuery("(max-width: 768px)");
  const videoRef = useRef(null);
  const logoRef = useRef(null);
  const [mp4, setMp4Src] = useState('');
  const [webm, setWebmSrc] = useState('');
  
  useLayoutEffect(() => {
    if (isMobile) {
      setMp4Src("assets/videos/web_vid_mob.mp4");
      setWebmSrc("assets/videos/web_vid_mob.webm");
    }
    else {
      setMp4Src("assets/videos/web_vid.mp4");
      setWebmSrc("assets/videos/web_vid.webm");
    }
    videoRef.current.load()

  }, [isMobile, mp4, webm])
  
  // Set the video height to 16:9
  useEffect(() => {
    containerRef.current.style.height = window.innerHeight + "px";
  }, [containerRef]);

  useEffect(() => {
    if (videoRef.current)
      isInView ? videoRef.current.play() : videoRef.current.pause();
  }, [isInView]);

  useEffect(() => {
    if (document.readyState !== "loading") {

      setTimeout(() => {
        if (logoRef.current) logoRef.current.classList.add("reveal");
      }, 1000);
    }

    return () => clearTimeout();
  }, []);

  return (
    <div className="video-container" id="atf" ref={containerRef}>
        <video
          ref={videoRef}
          autoPlay
          loop
          preload="none"
          muted
          playsInline
        >
            <source src={mp4} type="video/mp4" />
            <source src={webm} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      <div ref={logoRef} className="main-logo">
        <h1>Sam Fazakerley</h1>
        <svg
          width="349"
          height="42"
          viewBox="0 0 349 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.84389 5.53756H22.1539C25.5739 5.53756 28.5889 6.19756 31.1989 7.51756C33.8389 8.80756 35.8789 10.6376 37.3189 13.0076C38.7889 15.3776 39.5239 18.1376 39.5239 21.2876C39.5239 24.4376 38.7889 27.1976 37.3189 29.5676C35.8789 31.9376 33.8389 33.7826 31.1989 35.1026C28.5889 36.3926 25.5739 37.0376 22.1539 37.0376H7.84389V5.53756ZM21.7939 31.0526C24.9439 31.0526 27.4489 30.1826 29.3089 28.4426C31.1989 26.6726 32.1439 24.2876 32.1439 21.2876C32.1439 18.2876 31.1989 15.9176 29.3089 14.1776C27.4489 12.4076 24.9439 11.5226 21.7939 11.5226H15.1339V31.0526H21.7939Z" />
          <path d="M49.0716 5.53756H56.3616V37.0376H49.0716V5.53756Z" />
          <path d="M89.1722 20.7926H95.8322V33.5726C94.1222 34.8626 92.1422 35.8526 89.8922 36.5426C87.6422 37.2326 85.3772 37.5776 83.0972 37.5776C79.8272 37.5776 76.8872 36.8876 74.2772 35.5076C71.6672 34.0976 69.6122 32.1626 68.1122 29.7026C66.6422 27.2126 65.9072 24.4076 65.9072 21.2876C65.9072 18.1676 66.6422 15.3776 68.1122 12.9176C69.6122 10.4276 71.6822 8.49256 74.3222 7.11256C76.9622 5.70256 79.9322 4.99756 83.2322 4.99756C85.9922 4.99756 88.4972 5.46256 90.7472 6.39256C92.9972 7.32256 94.8872 8.67256 96.4172 10.4426L91.7372 14.7626C89.4872 12.3926 86.7722 11.2076 83.5922 11.2076C81.5822 11.2076 79.7972 11.6276 78.2372 12.4676C76.6772 13.3076 75.4622 14.4926 74.5922 16.0226C73.7222 17.5526 73.2872 19.3076 73.2872 21.2876C73.2872 23.2376 73.7222 24.9776 74.5922 26.5076C75.4622 28.0376 76.6622 29.2376 78.1922 30.1076C79.7522 30.9476 81.5222 31.3676 83.5022 31.3676C85.6022 31.3676 87.4922 30.9176 89.1722 30.0176V20.7926Z" />
          <path d="M106.654 5.53756H113.944V37.0376H106.654V5.53756Z" />
          <path d="M131.995 11.4776H121.915V5.53756H149.365V11.4776H139.285V37.0376H131.995V11.4776Z" />
          <path d="M176.5 30.2876H161.875L159.085 37.0376H151.615L165.655 5.53756H172.855L186.94 37.0376H179.29L176.5 30.2876ZM174.205 24.7526L169.21 12.6926L164.215 24.7526H174.205Z" />
          <path d="M194.258 5.53756H201.548V31.0976H217.343V37.0376H194.258V5.53756Z" />
          <path d="M263.005 30.2876H248.38L245.59 37.0376H238.12L252.16 5.53756H259.36L273.445 37.0376H265.795L263.005 30.2876ZM260.71 24.7526L255.715 12.6926L250.72 24.7526H260.71Z" />
          <path d="M300.834 37.0376L294.759 28.2626H288.054V37.0376H280.764V5.53756H294.399C297.189 5.53756 299.604 6.00256 301.644 6.93256C303.714 7.86256 305.304 9.18256 306.414 10.8926C307.524 12.6026 308.079 14.6276 308.079 16.9676C308.079 19.3076 307.509 21.3326 306.369 23.0426C305.259 24.7226 303.669 26.0126 301.599 26.9126L308.664 37.0376H300.834ZM300.699 16.9676C300.699 15.1976 300.129 13.8476 298.989 12.9176C297.849 11.9576 296.184 11.4776 293.994 11.4776H288.054V22.4576H293.994C296.184 22.4576 297.849 21.9776 298.989 21.0176C300.129 20.0576 300.699 18.7076 300.699 16.9676Z" />
          <path d="M323.99 11.4776H313.91V5.53756H341.36V11.4776H331.28V37.0376H323.99V11.4776Z" />
        </svg>
      </div>
    </div>
  );
};
